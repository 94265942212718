import habitat from 'preact-habitat';
import WidgetLoader from './routes/widgets';
import React, {h} from "preact";
import 'style/index.css'

const Widget = ({name}) => <WidgetLoader name={name}/>;

function init() {
    habitat(Widget).render({
        selector: '#widget',
        inline: false,
        clean: false
    });
}

// in development, set up HMR:
// if (module.hot) {
//     require('preact/devtools'); // enables React DevTools, be careful on IE
//     module.hot.accept(['./routes/widgets'], () => requestAnimationFrame(init));
// }

init();
